import { Exact, MatchmakeQuery, MatchmakeRequest, useMatchmakeLazyQuery } from 'generated/graphql';

import { LazyQueryHookOptions } from '@apollo/client';
import { useRouter } from 'next/router';

export const useMatchMake = (
  options?: LazyQueryHookOptions<
    MatchmakeQuery,
    Exact<{
      request: MatchmakeRequest;
    }>
  >
) => {
  const router = useRouter();

  const [matchMake, matchMakeResult] = useMatchmakeLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const sessionUrl = data?.matchMake?.sessionUrl;

      if (sessionUrl) {
        const qParams = sessionUrl.substring(sessionUrl.indexOf('?'));

        router.push('/play/' + qParams);
      }
    },
    ...options,
  });

  const handleMatchMake = (request: MatchmakeRequest) => {
    return matchMake({
      variables: {
        request: { clientNetworkProtocolVersion: null, clientVersion: null, ...request },
      },
    });
  };

  return [handleMatchMake, matchMakeResult] as const;
};
